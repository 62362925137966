.flex-container {
  display: flex;
  justify-content: flex-start;
  height: 13.75em;
  font-weight: 400;
}

.flex-container > div {
  text-align: center;
  justify-content: center;
  justify-items: center;
}

.card-body-value {
  height: 80%;
  padding-top: 30%;
}

.card-body-heading {
  height: 20%;
  width: 100%;
  font-weight: 600;
}

.card-body-bold {
  font-weight: 600;
}

.diffViewerStyle {
  height: inherit;
  background-color: white;
  overflow: scroll;
  width: 50%;
}

.diffViewerWidth {
  width: 40%;
}

.filterButton {
  margin: 5px;
}

.labelCss {
  margin: 5px;
  margin-top: 7px;
}

.time-picker-container > .ant-picker {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.filter-container > .modal-content {
  position: absolute;
}

.save-workflow-draft-modal-container {
  width: 50vw;
}

.save-workflow-draft-modal-container .modal-body > div:nth-child(2) {
  height: max-content;
  height: 60vh;
}

.save-workflow-draft-modal-container
  .modal-content
  .modal-body
  > div:nth-child(2)
  div {
  height: 100%;
}

#dropdownmenu {
  margin-right: 1.25em;
}

#workflowoverviewpage {
  margin: 1.875em;
}

.tableContainer div:nth-child(2) {
  padding: 0px;
}

#recentExecutions {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

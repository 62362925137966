.parent {
  display: flex;
  flex-direction: row;
}

.titleCol {
  flex: 1;
}

.selectCol {
  width: 9.375em;
}

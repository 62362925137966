.diffViewerWidth {
  width: 50%;
}

.diffViewerHeight {
  height: 43em;
}

.diffModal > .modal-content > .modal-body {
  overflow: hidden;
}

.diffViewerHeight div {
  height: inherit;
}
